import React from "react";
import { Chip } from "@mui/material";

const severityMap = {
  UNDER_INVESTIGATION: { color: "#FFCC00", text: "Under Investigation" },
  AFFECTED: { color: "#FF3B30", text: "Affected" },
  FIX_NOT_PLANNED: { color: "#FF9500", text: "Fix Not Planned" },
  NOT_AFFECTED: { color: "#34C759", text: "Not Affected" },
  FIXED: { color: "#34C759", text: "Fixed" },
  PENDING_UPSTREAM_FIX: { color: "#FF9500", text: "Pending Upstream Fix" },
};

function ResolutionChip({ status }) {
  const { color = "#000" } = severityMap[status?.toUpperCase()] || {};
  return (
    <Chip
      label={severityMap[status?.toUpperCase()].text || "UNKNOWN"}
      sx={{
        border: `1px solid ${color}`,
        backgroundColor: "#fff",
        "& .MuiChip-label": {
          color: color,
          fontWeight: 600,
        },
      }}
    />
  );
}

export default ResolutionChip;
