import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ResolutionChip from "../securityAdvisory/resolutionChips";

function PackageTable({ packages }) {
  const [search, setSearch] = useState("");

  const filteredpackages = packages.filter(
    (row) => row.packageName.includes(search) || row.url.includes(search),
  );

  function convertToRelativeTime(timestampStr) {
    const timestamp = new Date(timestampStr);
    const now = new Date();
    const timeDiff = now - timestamp;
    if (timeDiff < 24 * 60 * 60 * 1000) {
      const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60)); // Get hours as an integer

      if (hoursAgo === 0) {
        return "Just now";
      } else if (hoursAgo === 1) {
        return "1 hour ago";
      } else {
        return `${hoursAgo} hours ago`;
      }
    }
    const day = String(timestamp.getDate()).padStart(2, "0");
    const month = String(timestamp.getMonth() + 1).padStart(2, "0");
    const year = timestamp.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function sortAndGetFirstStatus(vuln) {
    const sortedStatus = vuln.status.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    );
    return sortedStatus[0];
  }

  return (
    <Paper sx={{ padding: 3, mb: 4, boxShadow: "none" }}>
      <Box
        display="flex"
        flexDirection={"column"}
        sx={{
          alignItems: "left",
          gap: 2,
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Affected Packages
        </Typography>
      </Box>
      <TextField
        label="Search"
        variant="outlined"
        placeholder="Search packages"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ fill: "#A0AEC0" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          marginBottom: 3,
          borderRadius: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px",
          },
        }}
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* Define table header cells */}
              {[
                "Name",
                "Status",
                "Podarmor Updates",
                "Fixed Version",
                "Last Updated",
              ].map((title) => (
                <TableCell
                  key={title}
                  sx={{
                    border: "none",
                    backgroundColor: "#F8F9FA!important",
                    fontWeight: 600,
                    pt: 1.5,
                    pb: 1.5,
                  }}
                >
                  <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                    {title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredpackages.map((row) => (
              <TableRow key={row.version}>
                <TableCell sx={{ border: "none" }}>
                  <Typography
                    variant="body1"
                    color="#4FD1C5"
                    sx={{ fontWeight: 500 }}
                  >
                    {row.packageName}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  <ResolutionChip status={sortAndGetFirstStatus(row).status} />
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {sortAndGetFirstStatus(row).description}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {sortAndGetFirstStatus(row).fixedVersion}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {convertToRelativeTime(row.updatedAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default PackageTable;
