import ExampleMDX from './docs/_go.mdx';

const App = () => (
  <div>
    <h1>MDX Example</h1>
    <ExampleMDX />
  </div>
);

export default App;
