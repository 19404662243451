import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const TopBar = ({ title, desc, button }) => {
  const { isAuthenticated, user } = useAuth0();
  const [open, setOpen] = useState(false);
  const [dockerUrl, setDockerUrl] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const _response = await axios.post("/api/user/images", {
        url: dockerUrl,
        userEmail: user.email,
      });
      // Close the dialog after successful submission
      handleClose();
    } catch (error) {
      console.error("Error posting Docker URL:", error);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={4}
      pl={0}
    >
      <Container
        disableGutters
        sx={{
          ml: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#A0AEC0",
          }}
        >
          {desc}
        </Typography>
      </Container>
      {isAuthenticated && button && (
        <>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#4FD1C5",
              color: "white",
              borderRadius: 2,
              padding: "10px 20px",
              mr: 4,
              fontWeight: 500,
              boxShadow: "none",
            }}
            onClick={handleClickOpen}
          >
            <AddIcon sx={{ mr: 1, fill: "#FFF" }} />
            Request Image
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Enter Docker URL</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Docker URL"
                type="url"
                fullWidth
                variant="outlined"
                value={dockerUrl}
                onChange={(e) => setDockerUrl(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="#4FD1C5">
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#4FD1C5",
                  color: "white",
                  borderRadius: 2,
                  padding: "10px 20px",
                  fontWeight: 500,
                  boxShadow: "none",
                }}
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default TopBar;
