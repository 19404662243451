import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Box } from "@mui/material";
const DocumentationLookup = {
  nodejs: "/markdown/nodejs.md",
  php: "/markdown/php.md",
  postgres: "/markdown/postgres.md",
  python3: "/markdown/python3.md",
};

const extractName = (name) => {
  const parts = name.split("/");
  if (parts.length < 2) {
    return null;
  }
  return parts[0]; // Extract the first part directly
};

const Documentation = ({ versions }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (versions.length === 0) return; // No versions provided

    const extractedName = extractName(versions[0].slug);
    if (!extractedName) return; // Ensure extracted name is valid

    const filePath = DocumentationLookup[extractedName];
    if (!filePath) {
      console.error("Documentation not found for:", extractedName);
      return; // Handle invalid documentation lookup
    }

    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((text) => setContent(text))
      .catch((error) => console.error("Error fetching markdown:", error));
  }, [versions]);

  return (
    <Box
      sx={{
        fontFamily: "monospace!important",
        pl: 3,
      }}
    >
      <ReactMarkdown>{content}</ReactMarkdown>
    </Box>
  );
};

export default Documentation;
