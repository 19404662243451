import React from "react";
import { Chip } from "@mui/material";

const severityMap = {
  negligible: { color: "#34C759", backgroundColor: "#34C7591A" },
  critical: { color: "#FFF", backgroundColor: "#FF3B30" },
  medium: { color: "#FF9500", backgroundColor: "#FF95001A" },
  unknown: { color: "#A0AEC0", backgroundColor: "#A0AEC01A" },
  high: { color: "#FF3B30", backgroundColor: "#FF3B301A" },
  low: { color: "#FFCC00", backgroundColor: "#FFCC001A" },
};

function StatusChips({ severity }) {
  const { color, backgroundColor } = severityMap[severity?.toLowerCase()] || {};

  return (
    <Chip
      label={severity}
      sx={{
        backgroundColor: backgroundColor,
        "& .MuiChip-label": {
          color: color,
          fontWeight: "600",
        },
      }}
    />
  );
}

export default StatusChips;
