import React, { useState } from "react";
import { Button, List, ListItem, Link, Box, Typography } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

const ReferencesDropdown = ({ CVE }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const references = [`https://nvd.nist.gov/vuln/detail/${CVE}`];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        background: "white",
        p: 3,
        borderRadius: 4,
      }}
    >
      <Button
        variant="text"
        onClick={toggleDropdown}
        style={{
          textTransform: "none",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          References
        </Typography>
        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Button>
      {isOpen && (
        <List sx={{ listStyleType: "disc", pl: 3, color: "#A0AEC0" }}>
          {references.map((ref, index) => (
            <ListItem
              key={index}
              sx={{ display: "list-item", padding: 0, color: "#A0AEC0" }}
            >
              <Link
                href={ref}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                  color: "#4FD1C5",
                  "&:hover": { textDecoration: "none" },
                }}
              >
                {ref}
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ReferencesDropdown;
