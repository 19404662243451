import React from "react";
import { Box, Typography } from "@mui/material";

function Description({ content }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: "white",
        p: 3,
        borderRadius: 4,
      }}
    >
      <Box display="flex" gap={2} flexDirection="column">
        <Box
          display="flex"
          flexDirection={"column"}
          sx={{
            alignItems: "left",
            gap: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Description
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" sx={{ color: "#A0AEC0" }}>
            {content}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Description;
