import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as FolderIcon } from "../../assets/images/images.svg";
import { ReactComponent as DocsIcon } from "../../assets/images/Vector.svg";
import { ReactComponent as SecuirtyIcon } from "../../assets/images/security.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Sidebar({ active }) {
  const [activeItem, setActiveItem] = useState(active);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [organizationName, setOrganizationName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const navigate = useNavigate();

  const { logout, loginWithRedirect, user, isAuthenticated } = useAuth0();

  const menuItems = [
    { text: "Images", key: "Images", icon: FolderIcon, route: "/images" },
    {
      text: "Docs",
      key: "Docs",
      icon: DocsIcon,
      route: "https://docs.podarmor.org/introduction",
    },
    {
      text: "Security Advisories",
      key: "Security",
      icon: SecuirtyIcon,
      route: "/security-advisories",
    },
  ];

  const handleItemClick = (item) => {
    setActiveItem(item.key);
    if (item.route.startsWith("http")) {
      window.location.href = item.route; // Open external links
    } else {
      navigate(item.route); // Navigate to internal routes
    }
  };

  useEffect(() => {
    const url = window.location.href;

    // check if url has a code parameter and login has not been triggered before
    if (
      url.includes("code=") &&
      !window.sessionStorage.getItem("redirectTriggered")
    ) {
      window.sessionStorage.setItem("redirectTriggered", "true"); // flag to prevent rerun
      loginWithRedirect();
    }

    if (
      isAuthenticated &&
      user &&
      !window.sessionStorage.getItem("userChecked")
    ) {
      window.sessionStorage.setItem("userChecked", "true"); // flag to prevent unnecessary check
      checkUserExists(user.email);
    }
  }, [isAuthenticated, user]);

  const checkUserExists = async (email) => {
    try {
      const res = await axios.get(`/api/user/users/exists`, {
        params: { email },
      });
      if (!res.data.exists) {
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error checking user:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        name: user.name,
        email: user.email,
        organization: organizationName,
      };
      await axios.post("/api/user/users", payload);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  return (
    isAuthenticated && (
      <Box
        sx={{
          minWidth: 250,
          backgroundColor: "#F8F9FA",
          padding: 0,
          background: "white",
          ml: 4,
          mt: "20px",
          borderRadius: 4,
          mb: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Logo Section */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: "auto",
            mt: 6,
            color: "black",
            mb: 6,
            gap: 1,
          }}
        >
          <Logo />
          <div>
            <b>POD</b>ARMOR
          </div>
        </Typography>

        {/* Menu List */}
        <List>
          {menuItems.map((item) => {
            const IconComponent = item.icon;
            const isActive = activeItem === item.key;
            const isHovered = hoveredItem === item.key;

            return (
              <ListItem key={item.key} disablePadding sx={{ mb: 1 }}>
                <ListItemButton
                  onClick={() => handleItemClick(item)}
                  onMouseEnter={() => setHoveredItem(item.key)}
                  onMouseLeave={() => setHoveredItem(null)}
                  sx={{
                    position: "relative",
                    padding: "10px 40px",
                    color: isActive ? "#4FD1C5" : "#A0AEC0",
                    backgroundColor: isActive
                      ? "rgba(79, 209, 197, 0.07)! important"
                      : "transparent",
                    "&:hover": {
                      color: "#4FD1C5",
                      backgroundColor: "#FFF",
                    },
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      top: 0,
                      height: "100%",
                      width: "4px",
                      backgroundColor: isActive ? "#4FD1C5" : "transparent",
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: isActive || isHovered ? "#4FD1C5" : "#A0AEC0",
                      minWidth: "auto",
                      marginRight: 2,
                    }}
                  >
                    <IconComponent
                      style={{
                        stroke: isActive || isHovered ? "#4FD1C5" : "#A0AEC0",
                        fill: isActive || isHovered ? "#4FD1C5" : "#A0AEC0",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      fontWeight: 600,
                      color: isActive || isHovered ? "#4FD1C5" : "#A0AEC0",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>

        {/* Logout Section */}
        <Box
          sx={{
            padding: "10px 20px",
            mt: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ListItemButton
            onClick={() => setShowLogout((prev) => !prev)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <ListItemIcon sx={{ minWidth: "auto" }}>
              <Avatar alt={user.name} src={user.picture} />
            </ListItemIcon>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <ListItemText
                primary={user.name}
                primaryTypographyProps={{ fontWeight: 500 }}
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.body2.fontSize,
                }}
              />
            </Box>
            {showLogout ? <KeyboardArrowUp /> : <KeyboardArrowRight />}
          </ListItemButton>
          {showLogout && (
            <Box>
              <Button
                onClick={() =>
                  logout({ returnTo: "https://podarmor.org/images" })
                }
                variant="outlined"
                color="error"
              >
                Logout
              </Button>
            </Box>
          )}
        </Box>

        {/* Organization Dialog */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Enter Your Organization Name</DialogTitle>
          <DialogContent>
            <TextField
              label="Organization Name"
              fullWidth
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={handleSubmit} disabled={!organizationName}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  );
}

export default Sidebar;
