import React, { useState, useEffect } from "react";
import { Container, Box } from "@mui/material";
import Header from "../components/cveDetails/cveHeader";
import BreadcrumbNav from "../components/image_details/navbar";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../components/common/sideBar";
import axios from "axios";
import Description from "../components/cveDetails/description";
import PackageTable from "../components/cveDetails/affectedPackages";
import ReferencesDropdown from "../components/cveDetails/references";

function convertToRelativeTime(timestampStr) {
  const timestamp = new Date(timestampStr);
  const now = new Date();
  const timeDiff = now - timestamp;
  if (timeDiff < 24 * 60 * 60 * 1000) {
    const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60)); // Get hours as an integer

    if (hoursAgo === 0) {
      return "Just now";
    } else if (hoursAgo === 1) {
      return "1 hour ago";
    } else {
      return `${hoursAgo} hours ago`;
    }
  }
  const day = String(timestamp.getDate()).padStart(2, "0");
  const month = String(timestamp.getMonth() + 1).padStart(2, "0");
  const year = timestamp.getFullYear();
  return `${day}-${month}-${year}`;
}

function CVEDetails() {
  const { CVEID } = useParams();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  const [CVE, setCVE] = useState("");
  const [CVEDetails, setCVEDetails] = useState(null);

  useEffect(() => {
    axios
      .get(`/api/data/vulns/${CVEID}`)
      .then((response) => {
        const { CVE } = response.data.returnVuln;
        setCVE(CVE);
        setCVEDetails(response.data.returnVuln);
      })
      .catch((error) => {
        console.error("Error fetching CVE details:", error);
      });
  }, [CVEID]);
  if (!CVEDetails) {
    return <div>Loading...</div>;
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        background: "#F8F9FA",
        minHeight: "100vh",
        maxHeight: "100vh",
      }}
    >
      <Sidebar active={"Security"} />
      <Container
        maxWidth="lg"
        sx={{
          mt: "20px",
          ml: 0,
          maxWidth: "none !important",
        }}
      >
        <BreadcrumbNav
          onBack={handleBackClick}
          title={"Security Advisories"}
          current={CVE}
        />
        <Box mt={3}>
          <Header
            name={CVE}
            severity={CVEDetails.severity}
            url={CVEDetails.url}
            published={convertToRelativeTime(CVEDetails.createdAt)}
            lastChanged={convertToRelativeTime(CVEDetails.updatedAt)}
          />
        </Box>
        <Box mt={3}>
          <Description content={CVEDetails.description} />
        </Box>
        <Box mt={3}>
          <ReferencesDropdown CVE={CVE} />
        </Box>
        <Box mt={3}>
          <PackageTable packages={CVEDetails.packageVulns} />
        </Box>
      </Container>
    </Box>
  );
}

export default CVEDetails;
