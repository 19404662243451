import React, { useEffect, useState } from "react";
import { Container, CssBaseline, Box } from "@mui/material";
import Sidebar from "../components/common/sideBar";
import TopBar from "../components/common/topBar";
import SummaryCard from "../components/securityAdvisory/summary";
import CVETable from "../components/securityAdvisory/table";
import axios from "axios";

function SecurityAdvisories() {
  const [vulns, setVulns] = useState([]);
  const [summary, setSummary] = useState([]);
  function summarizeVulnerabilities(vulnerabilities) {
    const summary = {
      FIXED: 0,
      UNDER_INVESTIGATION: 0,
      AFFECTED: 0,
      NOT_AFFECTED: 0,
      PENDING_UPSTREAM_FIX: 0,
      FIX_NOT_PLANNED: 0,
    };
    vulnerabilities.forEach((vuln) => {
      const status = vuln.status[0].status || ""; // If status is undefined, use empty string
      summary[status]++;
    });
    return summary;
  }

  function sortStatuses(vulnerabilities) {
    return vulnerabilities.map((vuln) => {
      return {
        ...vuln,
        status: vuln.status.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        ),
      };
    });
  }
  useEffect(() => {
    axios
      .get("/api/data/package-vulns")
      .then((response) => {
        const vulns = sortStatuses(response.data.packageVulns);
        setVulns(vulns);
        setSummary(summarizeVulnerabilities(vulns));
      })
      .catch((error) => {
        console.error("Error fetching versions:", error);
      });
  }, []); // Empty dependency array to run the effect only once

  return (
    <div style={{ display: "flex", background: "#F8F9FA", maxHeight: "100vh" }}>
      <CssBaseline />
      <Box
        display="flex"
        sx={{
          minHeight: "100vh",
          minWidth: "100%",
        }}
      >
        <Sidebar active={"Security"} />
        <Container
          sx={{
            mt: "20px",
            background: "rgb(255, 255,255)",
            borderRadius: 4,
            ml: 4,
            mr: 4,
            mb: 4,
            maxWidth: "none !important",
            p: 2,
          }}
        >
          <TopBar
            title={"Security Advisories"}
            desc={
              "View the status of known vulnerabilities in a Podarmor Image along with daily updates on security patches."
            }
          />
          <SummaryCard summary={summary} />
          <div>
            <CVETable displayedVulnerabilities={vulns} />
          </div>
        </Container>
      </Box>
    </div>
  );
}

export default SecurityAdvisories;
