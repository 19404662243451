import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";

const data = {
  summary: {
    FIXED: {
      text: "Fixed",
      color1: "#34C759",
      color2: "#34C759",
      backgroundColor: "#34C7591A",
    },
    AFFECTED: {
      text: "Affected CVEs",
      color1: "#FF3B30",
      color2: "#FF3B30",
      backgroundColor: "#FF3B301A",
    },
    UNDER_INVESTIGATION: {
      text: "Under Investigation",
      color1: "#FFCC00",
      color2: "#2D3748",
    },
    PENDING_UPSTREAM_FIX: {
      text: "Pending Upstream Fix",
      color1: "#FF9500",
      color2: "#2D3748",
    },
    NOT_AFFECTED: {
      text: "Not Affected",
      color1: "#A0AEC0",
      color2: "#2D3748",
    },
    FIX_NOT_PLANNED: {
      text: "Fix Not Planned",
      color1: "#A0AEC0",
      color2: "#2D3748",
    },
  },
};

const SummaryCard = ({ title, value, color1, color2, backgroundColor }) => (
  <Paper
    elevation={0}
    sx={{
      paddingLeft: 1.5,
      paddingRight: 1.5,
      paddingTop: 1.5,
      paddingBottom: 1.5,
      textAlign: "start",
      backgroundColor: backgroundColor,
      "& .MuiChip-label": {
        color: color1,
        fontWeight: "600",
      },
      borderRadius: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      gap: 0.5,
    }}
  >
    <Typography variant="body2" fontWeight="medium" color={color1}>
      {title}
    </Typography>
    <Typography variant="h5" fontWeight="bold" color={color2}>
      {value}
    </Typography>
  </Paper>
);

const CVESummary = ({ summary }) => {
  return (
    <>
      <Box mt={4} ml={3}>
        <Typography variant="h6" mb={2} sx={{ fontWeight: 600 }}>
          CVEs Summary
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(data.summary).map(([title, details]) => (
            <Grid item xs={12} sm={6} md={2} key={title}>
              <SummaryCard
                title={details.text}
                value={summary[title]}
                color1={details.color1}
                color2={details.color2}
                backgroundColor={details.backgroundColor}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default CVESummary;
