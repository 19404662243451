import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Tooltip,
  Box,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth0 } from "@auth0/auth0-react";

function VersionTable({ versions }) {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [search, setSearch] = useState("");

  const filteredVersions = versions
    .filter((row) => row.version.includes(search) || row.url.includes(search))
    .filter((row) => row.arch === "amd64 + arm64");

  function extractDate(timestamp) {
    const date = new Date(timestamp);
    const diff = Math.floor((new Date() - date) / (1000 * 60 * 60 * 24));
    return diff === 0 ? "Today" : diff === 1 ? "Yesterday" : `${diff} days ago`;
  }

  function bytesToMB(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
  }

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
  };

  return (
    <Paper sx={{ padding: 3, mb: 4, boxShadow: "none" }}>
      <TextField
        label="Search"
        variant="outlined"
        placeholder="Search Versions"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ fill: "#A0AEC0" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          marginBottom: 3,
          borderRadius: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px",
          },
        }}
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* Define table header cells */}
              {["Version", "Pull URL", "Size", "Last Updated"].map((title) => (
                <TableCell
                  key={title}
                  sx={{
                    border: "none",
                    backgroundColor: "#F8F9FA!important",
                    fontWeight: 600,
                    pt: 1.5,
                    pb: 1.5,
                  }}
                >
                  <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                    {title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVersions.map((row) => (
              <TableRow key={row.version}>
                <TableCell sx={{ border: "none" }}>
                  <Typography
                    variant="body1"
                    color="#4FD1C5"
                    sx={{ fontWeight: 500 }}
                  >
                    {row.version}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {isAuthenticated ? (
                    <Box display="flex" alignItems="center">
                      <Tooltip title="Copy URL">
                        <IconButton
                          size="small"
                          onClick={() => handleCopy(row.url)}
                        >
                          <ContentCopyIcon
                            fontSize="small"
                            style={{ color: "#4FD1C5" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        variant="body1"
                        noWrap
                        sx={{
                          maxWidth: 300,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                        }}
                      >
                        {row.url}
                      </Typography>
                    </Box>
                  ) : (
                    <Button
                      sx={{ color: "#4FD1C5" }}
                      onClick={() => {
                        loginWithRedirect();
                      }}
                    >
                      Login To Pull
                    </Button>
                  )}
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {bytesToMB(row.size)} Mb
                  </Typography>
                  <Typography variant="caption" color="#A0AEC0">
                    {row.platforms}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {extractDate(row.updatedAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default VersionTable;
