import React, { useState, useEffect } from "react";
import { Container, Box } from "@mui/material";
import Header from "../components/image_details/imageHeader";
import CommandBox from "../components/image_details/commandBox";
import TabNavigation from "../components/image_details/tabNavigation";
import BreadcrumbNav from "../components/image_details/navbar";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../components/common/sideBar";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
function ImageDetails() {
  const { isAuthenticated } = useAuth0();
  const { imageId } = useParams();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/images");
  };
  // search for param debug
  const [searchParams] = useSearchParams();
  const debug = searchParams.get("debug");
  const [versions, setVersions] = useState([]);
  const [imageNames, setImageNames] = useState("");
  useEffect(() => {
    axios
      .get(`/api/data/imageGroups/${imageId}`)
      .then((response) => {
        setImageNames(response.data["imageVersions"][0]["slug"]);
        setVersions(response.data["imageVersions"]);
      })
      .catch((error) => {
        console.error("Error fetching versions:", error);
      });
  }, [imageId]); // Only fetch data when imageId changes

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        background: "#F8F9FA",
        minHeight: "100vh",
        maxHeight: "100vh",
      }}
    >
      <Sidebar active={"Images"} />
      <Container
        maxWidth="lg"
        sx={{
          mt: "20px",
          ml: 0,
          maxWidth: "none !important",
        }}
      >
        <BreadcrumbNav
          title="Image Repository"
          current={imageNames ? (imageNames.split("/")[0] ?? "") : ""}
          onBack={handleBackClick}
        />
        <Box mt={3}>
          <Header name={imageNames} />
        </Box>
        {isAuthenticated && (
          <Box display="flex" justifyContent="space-between" mt={2} gap={2}>
            <CommandBox
              text="Login to container registry"
              textSecondary="Use the following command to login to the container registry with your token"
              command="docker login -u u18321 -p ajTP7t5vI9IJR9CzYZaf71nzZqAQPXJVr+8VTxMVw0+ACRAftLH9 parmor.azurecr.io"
            />
            <CommandBox
              text="Get started with image"
              textSecondary="Use the following command to pull the image to your local machine"
              command={
                "docker pull parmor.azurecr.io/" +
                (imageNames ? (imageNames.split(":")[0] ?? "") : "") +
                ":<version>"
              }
            />
          </Box>
        )}

        <Box mt={3}>
          <TabNavigation versions={versions} flag={debug} />
        </Box>
      </Container>
    </Box>
  );
}

export default ImageDetails;
