import React from "react";
import { Box, Typography } from "@mui/material";
import StatusChips from "../common/StatusChips";
import link from "../../assets/images/link.svg";

function Header({ name, severity, url, published, lastChanged }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: "white",
        p: 3,
        borderRadius: 4,
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <Box
          display="flex"
          flexDirection={"column"}
          sx={{
            alignItems: "left",
            gap: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {name}
          </Typography>
          <Box display="flex" gap={2}>
            {/* Assuming StatusChips already renders the severity */}
            <StatusChips severity={severity} />
            <Box
              sx={{
                borderRadius: 50,
                backgroundColor: "",
                pl: 1,
                pr: 1,
                display: "inline-flex",
                gap: 1,
                color: "#4FD1C5",
                alignItems: "center",
                border: "1px solid #4FD1C5",
                fontSize: "0.875rem",
                cursor: "pointer",
                transition: "background-color 0.3s, box-shadow 0.3s",
                "&:hover": {
                  backgroundColor: "#4FD1C51A",
                },
              }}
            >
              <a
                href={url}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {url}
              </a>
              <img
                src={link}
                style={{
                  Width: "0.875rem",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap={6}>
        <Box>
          <Typography variant="body2" color="textSecondary">
            Last Updated
          </Typography>
          <Typography variant="h6">{lastChanged}</Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary">
            Published
          </Typography>
          <Typography variant="h6">{published}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
